import React from 'react';
import Box from '@mui/material/Box';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Swatch } from 'react-color/lib/components/common';
import Tooltip from '../../../../../tooltip';

const WrappedSwatch = React.forwardRef(function WrappedSwatch(
  { onClick, style, color, ...props },
  ref,
) {
  return (
    <div ref={ref} {...props}>
      <Swatch onClick={onClick} style={style} color={color} />
    </div>
  );
});

function ColorsBar(props) {
  const { color, defaultColors, handleColorPick, resetColor } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderTop: '1px solid #bdbdbd',
        marginTop: '24px',
        paddingTop: '24px',
        alignItems: 'center',
        gap: '8px',
      }}
      data-testid="ReactColorPicker/ColorsBar"
    >
      {resetColor && (
        <Swatch
          onClick={resetColor}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
          }}
        >
          <DoDisturbIcon
            sx={{ fontSize: 27 }}
            data-testid="ColorsBar/SetDefaultIcon"
          />
        </Swatch>
      )}
      {defaultColors.map((colorObj, index) => {
        const activeColor = colorObj.color === color;
        return (
          <Tooltip
            title={colorObj.label}
            key={index}
            placement="top"
          >
            <WrappedSwatch
              onClick={() => handleColorPick(colorObj)}
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                boxShadow: activeColor ? `${color} 0px 0px 4px` : 'none',
              }}
              color={colorObj.color}
            />
          </Tooltip>
        );
      })}
    </Box>
  );
}

export default ColorsBar;
