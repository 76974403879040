import styled from 'styled-components';
import { APP_SETTINGS_PRIMARY_COLOR } from '../../../constants';

export const StyledInput = styled.input.attrs((props) => ({
  type: props.type || 'text',
  autoComplete: props.noAutocomplete && 'nope',
}))`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'var(--white)'};
  padding: 0.5rem;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid var(--border-color-primary);
  border-radius: 8px;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  line-height: 2;
  -moz-appearance: textfield;

  &:focus {
    background-color: var(--white);
    box-shadow: none;
    outline: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledChatbotPhoneInput = styled.input.attrs((props) => ({
  type: props.type || 'text',
}))`
  && {
    background-color: #333;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 8px;
    border-width: 0;
    width: 100%;
    margin: 0;
    color: #fff;
    flex: 1;
    border-radius: ${(props) => (props.actionButton ? '8px 0 0 8px' : '8px')};
    line-height: 2;

    &:disabled {
      opacity: 1;
    }

    &:focus {
      border-width: 0px;
      box-shadow: none;
      outline: 0;
    }

    &:hover {
      border-width: 0px;
      box-shadow: none;
    }

    & ~ button {
      flex: 0.4;
      margin: 0;
      border-radius: 0 8px 8px 0;
    }
  }
`;

export const StyledInputNoLeftRadius = styled(StyledInput)`
  border-radius: 0 8px 8px 0;
  border-left: none;
`;

export const StyledDraggableInput = styled(StyledInput)`
  border-radius: 0 8px 8px 0;
  padding-left: 5px;
  border-left: none;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: stretch;
  position: relative;
  margin: ${(props) => (props.noMargin ? '0' : '0.5rem 0 1rem 0')};
  width: 100%;
`;

export const StyledErrorMessage = styled.span`
  color: var(--white);
  background-color: var(--error);
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  padding: 3px 9px;
  position: absolute;
  right: 5px;
  top: -13px;
  z-index: 1;
`;

export const InputPrefix = styled.span`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  justify-content: center;
  width: 3rem;
  background-color: var(--new-item-button-background);
  color: var(--font-color-primary);
  border: 1px solid var(--border-color-primary);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  line-height: 2;

  img {
    display: flex;
  }
`;

export const InputPrefixInline = styled(InputPrefix)`
  background-color: #fff;
  width: 34px;
  height: 50px;
`;

export const ColorBox = styled.div`
  border-radius: 8px;
  background-color: ${(props) =>
    props.color ? props.color : APP_SETTINGS_PRIMARY_COLOR};
  position: absolute;
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
`;
