import React from 'react';
import _isEmpty from 'lodash/isEmpty';

export default function useWorkspace() {
  const path = window.location.pathname;
  const workspaceId = parseInt(path.split('/')[1], 10);

  if (!isNaN(workspaceId)) {
    return workspaceId;
  } else {
    return null;
  }
}
