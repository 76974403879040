import { useState, useEffect } from 'react';
import wretch from 'wretch';

function usePulling({ url, headers, timeout, callback, onError, isPulling }) {
  const [intervalId, setIntervalId] = useState(null);

  const runPulling = () => {
    wretch(url)
      .headers(headers)
      .get()
      .json(callback)
      .catch(onError);
  };

  const startPulling = () => {
    const intId = setInterval(runPulling, timeout);
    setIntervalId(intId);

    return intId;
  };

  const stopPulling = (intervalId) => {
    clearInterval(intervalId);
    setIntervalId(null);
  };

  useEffect(() => {
    if (!isPulling) {
      return stopPulling(intervalId);
    }

    const currentIntId = startPulling();

    return () => {
      stopPulling(currentIntId);
    };
  }, [isPulling]);
};

export default usePulling;
