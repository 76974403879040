import styled from 'styled-components';
import { Editable } from 'slate-react';

export const Container = styled.div`
  position: ${props => props.position};
  width: 100%;
`;

export const Input = styled(Editable)`
  display: block;
  background: var(--white);
  color: var(--font-color-secondary);
  border: 1px solid var(--border-color-primary);
  padding: 0.5rem 15px;
  border-radius: 8px;
  min-height: 48px;
  margin: 0px;
  box-sizing: border-box;
  line-height: 2;
  overflow: hidden;
  white-space: pre !important;

  & span.MuiChip-root, p, h1, h2, h3, h4 {
    margin: 0;
  }

  & h1 {
    line-height: 1.2;
  }

  & h2 {
    line-height: 1.4;
  }

  & h3 {
    line-height: 1.8;
  }

  & h4 {
    line-height: 2;
  }

  & small {
    line-height: 2.4;
  }

  ${(props) =>
    props.multirows &&
    `
    height: auto;
    white-space: pre-wrap !important;

    & p {
      margin: 0;
    }

    & span.MuiChip-root {
      margin: 1px 0;
    }
  `}

  ${(props) =>
    props.slateInput &&
    `
    height: auto;
    white-space: pre-wrap !important;

    &&.MuiInputBase-input {
      height: auto;
      box-sizing: content-box;
    }

    & p {
      margin: 0;
    }

    & span.MuiChip-root {
      margin: 1px 0;
    }

    h1, h2, h3, h4, small, span {
      line-height: 1.4;
    }

    span.withLineHeight {
      display: inline-block;
    }

    span.highlighted {
      padding-top: 2px;
      padding-bottom: 4px;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    }


    ul li span.withLineHeight,
    ol li span.withLineHeight {
      display: inline;
    }

    ul,
    ol {
      margin-left: 1em;
      padding: unset;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }
  `}

  ${(props) =>
    props.label &&
    `
    display: flex;
    &::before {
      content: "${props.label}";
      display: block;
      margin-right: 6px;
      color: #bdbdbd;
      font-weight: 500;
    }
  `}

  ${(props) =>
    props.readOnly &&
    `
    & .MuiChip-deleteIcon {
      display: none;
    }

    && span.MuiChip-root {
      height: auto;
      background-color: transparent;
      margin: 0;
      line-height: 1.4;
    }

    & .MuiChip-label {
      font-size: 16px;
      padding-left: 0;
      padding-right: 0;
    }
  `}
`;
