import React from 'react';
import PropTypes from 'prop-types';
import MUISelect from '@mui/material/Select';
import { Editor } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import MenuItem from '@mui/material/MenuItem';

const currentEditorMark = (editor, attr) => {
  const marks = Editor.marks(editor);
  return marks && marks[attr];
};

function Select({ menuItems, defaultValue, variant, ariaLabel, onChange, onPopover }) {
  const [value, setValue] = React.useState(defaultValue);
  const editor = useSlate();
  const currentValue = currentEditorMark(editor, variant);

  React.useEffect(() => {
    if (onPopover) {
      onPopover(false);
    }
  }, []);

  React.useEffect(() => {
    const newValue = currentValue || defaultValue;
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  }, [currentValue, defaultValue]);

  const handleOpen = () => {
    if (onPopover) {
      onPopover(true);
    }
  };

  const handleClose = () => {
    if (onPopover) {
      onPopover(false);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
    editor.addMark(variant, value);
  };

  const handleItemClick = () => {
    setTimeout(() => {
      handleClose();
      ReactEditor.focus(editor);
    }, 0);
  };

  return (
    <MUISelect
      onChange={handleChange}
      onOpen={handleOpen}
      aria-label={ariaLabel}
      sx={{
        minWidth: 120,
        maxWidth: 120,
        height: 36,
        ml: 1,
        '&:first-of-type': {
          ml: 0,
        },
        '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
          color: menuItems.find(item => item.value.toString() === value.toString())?.disabled ? 'rgba(0, 0, 0, 0.4)' : 'currentcolor',
          pt: 1,
          pb: 1,
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'inherit',
          borderWidth: '1px',
        },
      }}
      value={value}
    >
      {menuItems.map(({ label, value, disabled }) => (
        <MenuItem
          onClick={handleItemClick}
          key={value}
          sx={{ fontFamily: value }}
          value={value}
          disabled={disabled}
        >
          {label}
        </MenuItem>
      ))}
    </MUISelect>
  );
}

Select.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), label: PropTypes.string }),
  ).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  variant: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
};

export default Select;
