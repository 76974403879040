import React from 'react';
import Mention from './Mention';
import { getListItemStyle } from './utils';

const isPlaceholder = (element) =>
  element.children?.length === 1 && element.children[0].text === '';

function ListWrapper({ align, children }) {
  return align ? <div style={{ textAlign: align }}>{children}</div> : children;
}

function Element(props) {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };

  switch (element.type) {
    case 'link':
      return (
        <a style={style} {...attributes} target="_blank" href={element.url}>
          {children}
        </a>
      );
    case 'bulleted-list':
      return (
        <ListWrapper align={element.children[0]?.align}>
          <ul style={{ ...style, display: 'inline-block' }} {...attributes}>
            {children}
          </ul>
        </ListWrapper>
      );
    case 'list-item':
      const color = getListItemStyle(element, 'color', 'inherit');
      const fontFamily = getListItemStyle(element, 'fontFamily', 'inherit');
      const fontWeight = getListItemStyle(element, 'fontWeight', '400');
      const letterSpacing = getListItemStyle(element, 'letterSpacing', 'normal');
      const textTransform = getListItemStyle(element, 'textTransform', 'none');
      const lineHeight = getListItemStyle(element, 'lineHeight', '1.5');
      return <li style={{ color, letterSpacing, textTransform, lineHeight, fontFamily, fontWeight, textAlign: 'left' }} {...attributes}>{children}</li>;
    case 'numbered-list':
      return (
        <ListWrapper align={element.children[0]?.align}>
          <ol style={{ ...style, display: 'inline-block', 'listStylePosition': 'inside', marginLeft: '0px' }} {...attributes}>
            {children}
          </ol>
        </ListWrapper>
      );
    case 'mention':
      return <Mention {...props} />;
    case 'heading-1':
      if (!isPlaceholder(element)) {
        return <h1 style={style} {...attributes}>{children}</h1>;
      }
    case 'heading-2':
      if (!isPlaceholder(element)) {
        return <h2 style={style} {...attributes}>{children}</h2>;
      }
    case 'heading-3':
      if (!isPlaceholder(element)) {
        return <h3 style={style} {...attributes}>{children}</h3>;
      }
    case 'heading-4':
      if (!isPlaceholder(element)) {
        return <h4 style={style} {...attributes}>{children}</h4>;
      }
    case 'paragraph':
      if (!isPlaceholder(element)) {
        return <p style={style} {...attributes}>{children}</p>;
      }
    case 'rich-text-block':
      if (!isPlaceholder(element)) {
        return <div style={style} {...attributes}>{children}</div>;
      }
    case 'caption':
      if (!isPlaceholder(element)) {
        return <small style={style} {...attributes}>{children}</small>;
      }
    default:
      return <p style={style} {...attributes}>{children}</p>;
  }
}

export default Element;
