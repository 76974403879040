import React from 'react';
import PropTypes from 'prop-types';

function Icon({ color, isActive }) {
  const mainColor = isActive ? '#FFF' : '#333';

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.18827 11.0002L6.08427 8.63423H9.86427C10.1583 9.46023 10.4523 10.2022 10.7463 11.0002H13.1543C11.9643 7.83623 10.6063 4.19623 9.41627 0.990234H6.65827C5.20227 4.65823 3.71827 8.60623 2.82227 11.0002H5.18827ZM6.70027 6.81423C7.13427 5.48423 7.59627 4.14023 7.94627 2.89423H7.97427C8.33827 4.11223 8.71627 5.17624 9.23427 6.81423H6.70027Z"
        fill={mainColor}
      />
      <rect y="13" width="16" height="3" fill={color} />
    </svg>
  );
}

Icon.propTypes = {
  color: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default Icon;
