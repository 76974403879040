import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { CustomPicker } from 'react-color';
import SaturationBar from './components/SaturationBar';
import InputBar from './components/InputBar';
import HueBar from './components/HueBar';
import AlphaBar from './components/AlphaBar';
import ColorsBar from './components/ColorsBar';

function ReactColorPicker(props) {
  return (
    <Box
      sx={{
        padding: '16px',
        borderRadius: '16px',
        background: '#fff',
        width: '100%',
        maxWidth: 352,
        ...(props.containerStyles || {}),
      }}
      data-testid="ReactColorPicker/Container"
    >
      <SaturationBar {...props} />
      <InputBar {...props} />
      <HueBar {...props} />
      {props.withAlpha && <AlphaBar {...props} />}
      {!props.withoutColorsBar && props.paletteColors && (
        <ColorsBar
          {...props}
          defaultColors={props.paletteColors}
        />
      )}
    </Box>
  );
}

ReactColorPicker.propTypes = {
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleColorPick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onChangeComplete: PropTypes.func,
  resetColor: PropTypes.func,
};

export default CustomPicker(ReactColorPicker);
