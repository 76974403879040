import { useState, useCallback } from 'react';

export default function useMuiPopover(popoverId, anchorId) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = popoverId || undefined;

  const handleOpen = useCallback((event) => {
    const anchor = anchorId
      ? event.currentTarget.querySelector(`#${anchorId}`)
      : event.currentTarget;
    setAnchorEl(anchor);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return {
    id,
    open,
    anchorEl,
    handleOpen,
    handleClose,
  };
}
