import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { Editor, Transforms, Range, Element as SlateElement } from 'slate';

import Button from '../ChangeButton';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const isBlockActive = (editor, format, blockType = 'type') => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
  });

  return !!match;
};

const isEmptyLastListItem = (node) => {
  const item = node.children[node.children.length - 1];

  if (item) {
    const leaf = item.children[item.children.length - 1];

    if (leaf && leaf.text === '') {
      return true;
    }
  }

  return false;
};

const withDeleteEmptyBlock = (editor) => {
  const { deleteBackward } = editor;

  editor.deleteBackward = (unit) => {
    const { selection } = editor;

    if (
      selection &&
      selection.focus.offset === 0 &&
      selection.anchor.offset === 0 &&
      Range.isCollapsed(selection)
    ) {
      const node = editor.children[selection.anchor.path[0]];

      if (node && LIST_TYPES.includes(node.type)) {
        const children = node.children;

        if (children.length === 1) {
          toggleBlock(editor, node.type);
        }

        if (children.length !== 1 && isEmptyLastListItem(node)) {
          toggleBlock(editor, node.type);
          return;
        }
      }
    }

    deleteBackward(unit);
  };

  return editor;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
    !Editor.isEditor(n) &&
    SlateElement.isElement(n) &&
    LIST_TYPES.includes(n.type) &&
    !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const pickIcon = (format) => {
  switch (format) {
    case 'bulleted-list':
      return FormatListBulletedIcon;
    case 'numbered-list':
      return FormatListNumberedIcon;
    case 'left':
      return FormatAlignLeftIcon;
    case 'center':
      return FormatAlignCenterIcon;
    case 'right':
      return FormatAlignRightIcon;
  }
};
const POPOVERS = {
  'bulleted-list': 'Bulleted List',
  'numbered-list': 'Numbered List',
  left: 'Left',
  center: 'Center',
  right: 'Right',
};

function BlockButton({ format, onClick }) {
  const editor = useSlate();
  const Icon = useMemo(() => pickIcon(format), [format]);
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
  const iconColor = isActive ? '#FFF' : '#333';
  const popover = POPOVERS[format];
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    toggleBlock(editor, format);
  };

  return (
    <Button
      isActive={isActive}
      handleClick={handleClick}
      popover={popover}
      buttonProps={{
        'data-testid': `TitleSection/BlockButton/${format}`,
      }}
    >
      <Icon sx={{ color: iconColor, fontSize: 20 }} data-testid={`BlockButton/${format}Icon`} />
    </Button>
  );
}

BlockButton.propTypes = {
  format: PropTypes.string.isRequired,
};

export { withDeleteEmptyBlock };
export default BlockButton;
