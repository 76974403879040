import React, { useState } from 'react';
import { useSlate } from 'slate-react';
import { Editor } from 'slate';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '../ChangeButton';
import Menu from '@mui/material/Menu';
import LetterSpacing from '../LetterSpacing';
import TextCase from '../TextCase';
import LineHeight from '../LineHeight';
import { useMuiPopover } from '../../../../../hooks';
import { MenuItem } from '@mui/material';

const getActiveValue = (editor, name, defaultValue) => {
  const marks = Editor.marks(editor);

  if (marks) {
    return marks[name] || defaultValue;
  } else {
    return defaultValue;
  }
};

function LetterSettings({ onPopover }) {
  const editor = useSlate();
  const [selection, setSelection] = useState(null);
  const {
    id,
    open,
    anchorEl,
    handleOpen: handleSettingsOpen,
    handleClose: handleSettingsClose,
  } = useMuiPopover('ButtonsContainer/LetterSettings');
  const activeLetterSpacing = getActiveValue(editor, 'letterSpacing', 'normal');
  const activeLineHeight = getActiveValue(editor, 'lineHeight', '1.5');

  const handleOpen = (event) => {
    if (onPopover) {
      onPopover(true);
    }
    handleSettingsOpen(event);
    setSelection(editor.selection);
  };

  const handleClose = (event) => {
    if (onPopover) {
      onPopover(false);
    }
    handleSettingsClose(event);
    setSelection(editor.selection);
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (anchorEl) {
      handleClose(event);
    } else {
      handleOpen(event);
    }
  };

  const handleSetFormating = (propsName) => (event, value) => {
    editor.selection = selection;
    editor.addMark(propsName, value);
    handleClose(event);
  };

  return (
    <>
      <Button
        aria-describedby={id}
        handleClick={handleClick}
        isActive={open}
        popover="Letter settings"
        buttonProps={{
          'data-testid': 'TitleSection/LetterSettings',
        }}
      >
        <MoreVertIcon sx={{ color: open ? '#fff' : '#1d1d1d' }} />
      </Button>
      <Menu id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem>
          <LetterSpacing
            onItemClick={handleSetFormating('letterSpacing')}
            selectedValue={activeLetterSpacing}
          />
        </MenuItem>
        <MenuItem>
          <TextCase onItemClick={handleSetFormating('textTransform')}/>
        </MenuItem>
        <MenuItem>
          <LineHeight
            onItemClick={handleSetFormating('lineHeight')}
            selectedValue={activeLineHeight}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

export default LetterSettings;
