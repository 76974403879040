import styled from 'styled-components';
import { darken, lighten } from 'polished';
import {
  APP_SETTINGS_PRIMARY_COLOR,
  APP_SETTINGS_PRIMARY_COLOR_DARKEN,
} from '../../constants';

const getHoverColor = (color) => {
  if (!color) {
    return APP_SETTINGS_PRIMARY_COLOR_DARKEN;
  }

  if (color.includes('var(--')) {
    return color;
  }

  return darken(0.1, color);
};

export const StyledButton = styled.button.attrs((props) => ({
  type: props.type || 'button',
}))`
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2;
  padding: 0.5rem;
  color: ${(props) =>
    props.color ? props.color : 'var(--font-color-secondary)'};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'var(--white)'};
  border: 1px solid transparent;
  border-radius: 0.5rem;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  transition: background-color 150ms ease;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;

  &:hover {
    color: ${(props) =>
      props.color ? props.color : 'var(--font-color-secondary)'};
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : 'var(--white)'};
  }

  &:focus {
    outline: 0;
  }

  ${(props) =>
    props.noMargin &&
    `
    margin: 0;
    `}

  ${(props) =>
    props.responsive &&
    `
    padding: 1rem;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    margin: 0;
    padding: 1rem;
    z-index: 1;
    box-shadow: 0 -15px 10px 0 rgba(0, 0, 0, 0.15);


    @media (min-width: 600px) {
      border-radius: 8px;
      box-shadow: none;
      margin: 0.5rem 0 1rem;
      position: static;
      z-index: 0;
    }
  `}
`;

export const StyledWhiteButton = styled(StyledButton)`
  color: var(--font-color-secondary);
  border: 1px solid var(--border-color-primary);

  &:hover,
  &:hover:disabled {
    color: var(--font-color-secondary);
    background-color: var(--white);
  }
`;

export const StyledChatbotLoadingButton = styled(StyledButton)`
  position: relative;
  background-color: var(--surface-background--light);
  border: 1px solid var(--border-color-primary);
  color: var(--font-color-primary);
  opacity: 1 !important;

  &&:hover {
    background-color: var(--surface-background--light);
    color: var(--font-color-primary);
  }
`;

export const StyledPrimaryButton = styled(StyledButton)`
  min-height: 50px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : APP_SETTINGS_PRIMARY_COLOR};
  color: ${(props) => (props.color ? props.color : '#fff')};
  border-color: ${(props) => (props.borderColor ? props.borderColor : 'none')};
  border-width: ${(props) => (props.borderWidth ? props.borderWidth : '0px')};
  border-style: ${(props) => (props.borderStyle ? props.borderStyle : 'solid')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0.5rem'};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : 'none')};

  &&:hover {
    background-color: ${(props) => getHoverColor(props.backgroundColor)};
    color: ${(props) => (props.color ? props.color : '#fff')};
  }
`;

export const StyledSubmissionButton = styled(StyledPrimaryButton)(
  (props) => ({
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    marginBottom: 0,
    ...props.styles,

    '&&:hover': {
      ...props.styles,
      backgroundColor: getHoverColor(props.styles.backgroundColor),
    },
  })
);

export const StyledNewItemButton = styled(StyledButton)`
  height: ${(props) => props.size === 'small' ? '35px' : '50px'};
  width: ${(props) => props.size === 'small' ? 'fit-content' : '100%'};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : 'var(--new-item-button-background)'};
  color: var(--font-color-primary);
  box-shadow: none;
  justify-content: left;
  border-width: 1px;
  border-style: dashed;
  border-color: var(--new-item-button-border-color);
  display: flex;
  font-weight: 400;
  margin: ${(props) => (props.noMargin ? '0' : '0.5rem 0 1rem 0')};

  &:hover {
    background-color: var(--new-item-button-background--hover);
  }

  &:disabled,
  &:disabled:hover {
    color: var(--disabled-input-color);
    background-color: var(--disabled-input-background);
  }
`;

export const StyledSmallDeleteButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: #ff432e;
  width: 25px;
  height: 25px;
  padding: 0.5rem;
  border: 2px solid #fff;
  border-radius: 50%;
  line-height: 3px;
  cursor: pointer;
  transition: background-color 150ms ease;
  margin: ${(props) => (props.noMargin ? '0' : '0.5rem 0 1rem')};

  &:hover {
    background-color: ${darken(0.15, '#ff432e')};
  }

  &:focus {
    outline: 0;
  }
`;

export const StyledLinkWithIcon = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:active {
    color: var(--primary-color-darken);
  }
  & svg {
    margin-right: 4px;
    margin-bottom: 2px;
  }
`;

export const StyledLinkButton = styled.a`
  -webkit-font-smoothing: antialiased;
  appearance: none;
  background-color: #f2f2f2;
  border-radius: 8px;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 2;
  margin: 0.5rem 0 1rem;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  &:hover {
    background-color: ${lighten(0.05, '#F2F2F2')};
    color: #000;
  }

  &:active {
    background-color: #eee;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    &:hover {
      background-color: #fff;
    }
  }
`;

export const StyledPrimaryLinkButton = styled(StyledLinkButton)`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : APP_SETTINGS_PRIMARY_COLOR};
  color: #fff;

  &&:hover {
    background-color: ${(props) => getHoverColor(props.backgroundColor)};
    color: #fff;
  }
`;
