import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Span = styled.span`
  border-radius: 5px;
  text-align: center;
  background-color: #ff432e;
  color: #fff;
  font-size: 12px;
  padding: 3px 9px;
  ${(props) =>
    props.position === 'right-top' &&
    `
      position: absolute;
      right: 5px;
      top: -13px;
  `}
`;

function ErrorMessage({ text, position, style }) {
  return <Span position={position} style={style}>{text}</Span>;
}

ErrorMessage.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  position: PropTypes.string,
};

export default ErrorMessage;
