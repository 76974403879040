import React from 'react';
import useDebounce from './useDebounce';

export default function useWindowSize() {
  const [windowWidth, setWindowWidth] = React.useState();
  const debouncedValue = useDebounce(windowWidth, 300);
  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return debouncedValue;
}
