import React from 'react';
import Box from '@mui/material/Box';
import { Saturation } from 'react-color/lib/components/common';

function Pointer() {
  return (
    <div
      style={{
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        border: '2px solid #fff',
        background: 'transparent',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
      }}
    />
  );
}

function SaturationBar(props) {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '240px',
        borderRadius: '16px',
        overflow: 'hidden',
      }}
    >
      <Saturation
        {...props}
        pointer={Pointer}
        onChange={props.onChange}
        width="100%"
      />
    </Box>
  );
}

export default SaturationBar;
