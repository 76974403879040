import { useState } from 'react';
import { getExperiencesList, getBlocksList, getReferencesList } from '../api';

function useGetTreeRefs(setExpanded) {
  const [experiences, setExperiences] = useState([]);

  const handleOpenExperiences = (nodeId) => () => {
    if (experiences.length) {
      setExpanded(nodeId);
      return;
    }
    getExperiencesList().then((response) => {
      setExperiences(
        response.map(({ title, value }) => ({
          name: title,
          value,
          blocks: [],
        })),
      );
      setExpanded(nodeId);
    });
  };

  const handleOpenBlocks = (experienceId, nodeId) => () => {
    const currentExperience = experiences.find(
      (experience) => experience.value === experienceId,
    );
    if (currentExperience.blocks.length) {
      setExpanded(nodeId);
      return;
    }

    getBlocksList(experienceId).then((response) => {
      setExperiences(
        experiences.map((experience) => {
          if (experienceId === experience.value) {
            return {
              ...experience,
              blocks: response.map(({ title, value, responseOption }) => {
                return {
                  name: title,
                  value,
                  responseOption,
                  references: [],
                };
              }),
            };
          } else {
            return experience;
          }
        }),
      );
      setExpanded(nodeId);
    });
  };

  const handleOpenReferences = (experienceId, blockId, nodeId) => () => {
    const currentExperience = experiences.find(e => e.value === experienceId);
    const currentBlock = currentExperience.blocks.find(b => b.value === blockId);
    if (currentBlock.references.length) {
      setExpanded(nodeId);
      return;
    }

    getReferencesList(blockId).then((response) => {
      setExperiences(
        experiences.map((experience) => {
          if (experience.value === experienceId) {
            return {
              ...experience,
              blocks: experience.blocks.map((block) => {
                if (block.value === blockId) {
                  return { ...block, references: response };
                } else {
                  return block;
                }
              }),
            };
          } else {
            return experience;
          }
        }),
      );
      setExpanded(nodeId);
    });
  };

  return {
    experiences,
    handleOpenExperiences,
    handleOpenBlocks,
    handleOpenReferences,
  };
}

export default useGetTreeRefs;
