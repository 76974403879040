import React from 'react';
import { EditableInput } from 'react-color/lib/components/common';

function ColorInput(props) {
  return (
    <EditableInput
      value={props.hex}
      onChange={props.onChange}
      style={{
        wrap: {
          height: '100%',
          flex: 1,
        },
        input: {
          height: '100%',
          width: '100%',
          margin: 0,
          fontSize: '16px',
          padding: '0.5rem',
          border: 'none',
          boxShadow: 'none',
          borderRadius: 0,
          outline: 'none',
        },
      }}
    />
  );
}

export default ColorInput;
