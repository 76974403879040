import _isString from 'lodash/isString';

export const getLabelMarginTop = (paddingTop, defaultPx) => {
  if (!paddingTop) {
    return defaultPx;
  }

  const pxNumber = Number(paddingTop.replace(/[^0-9.-]+/g, ''));

  return `${pxNumber + 3}px`;
};

export const getBooleanFromString = (value) => {
  return _isString(value) ? value === 'true' : value;
};
