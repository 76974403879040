import React from 'react';

export default function DragIcon({ fill }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 14C16 14.8284 16.6716 15.5 17.5 15.5C18.3284 15.5 19 14.8284 19 14C19 13.1716 18.3284 12.5 17.5 12.5C16.6716 12.5 16 13.1716 16 14Z"
        fill={fill}
      />
      <path
        d="M16 21C16 21.8284 16.6716 22.5 17.5 22.5C18.3284 22.5 19 21.8284 19 21C19 20.1716 18.3284 19.5 17.5 19.5C16.6716 19.5 16 20.1716 16 21Z"
        fill={fill}
      />
      <path
        d="M16 7C16 7.82843 16.6716 8.5 17.5 8.5C18.3284 8.5 19 7.82843 19 7C19 6.17157 18.3284 5.5 17.5 5.5C16.6716 5.5 16 6.17157 16 7Z"
        fill={fill}
      />
      <path
        d="M9 14C9 14.8284 9.67157 15.5 10.5 15.5C11.3284 15.5 12 14.8284 12 14C12 13.1716 11.3284 12.5 10.5 12.5C9.67157 12.5 9 13.1716 9 14Z"
        fill={fill}
      />
      <path
        d="M9 21C9 21.8284 9.67157 22.5 10.5 22.5C11.3284 22.5 12 21.8284 12 21C12 20.1716 11.3284 19.5 10.5 19.5C9.67157 19.5 9 20.1716 9 21Z"
        fill={fill}
      />
      <path
        d="M9 7C9 7.82843 9.67157 8.5 10.5 8.5C11.3284 8.5 12 7.82843 12 7C12 6.17157 11.3284 5.5 10.5 5.5C9.67157 5.5 9 6.17157 9 7Z"
        fill={fill}
      />
    </svg>
  );
}
