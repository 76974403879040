import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  padding: 0;
  margin: 0 2px;
  background-color: ${(props) =>
    props.isActive
      ? 'var(--block-title-controls-background--hover)'
      : 'var(--block-title-controls-background)'};

  &:hover {
    background-color: var(--block-title-controls-background--hover);
  }
`;

export const PopoverText = styled.div`
  position: relative;
  background-color: var(--background-primary);
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 11px;
  text-align: center;
  color: var(--font-color-primary);
  border: 1px solid var(--border-color-primary);

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    width: 0.5rem;
    height: 0.5rem;
    background: var(--background-primary);
    border-bottom: 1px solid var(--border-color-primary);
    border-right: 1px solid var(--border-color-primary);
  }
`;
