import React, { useState, useCallback, useEffect } from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import Popover from '@mui/material/Popover';
import ReactColorPicker from '../../../ReactColorPicker';

import Button from '../ChangeButton';
import PreviewIcon from './components/Icon';
import { muiStyles } from '../HighlightPicker/style';
import { colorWithDefault, getInitialColor } from '../../utils';
import { rgbaObjToString } from '../../../../../../utils';

const currentColor = (editor) => {
  const marks = Editor.marks(editor);
  return marks && marks.color;
};

const initialColor = (editor, paletteColors) => {
  const marks = Editor.marks(editor);
  return getInitialColor(marks, 'color', paletteColors);
};

const DEFAULT_COLOR = 'rgba(255, 255, 255, 1)';

function ColorPicker({ onPopover, paletteColors }) {
  const editor = useSlate();
  const [anchoreEl, setAnchoreEl] = useState(null);
  const [colorValue, setColorValue] = useState('');
  const [selection, setSelection] = useState(null);

  const handleOpen = useCallback(
    (e) => {
      if (onPopover) {
        onPopover(true);
      }
      const color = initialColor(editor, paletteColors);

      if (color) {
        setColorValue(color);
      } else {
        setColorValue(DEFAULT_COLOR);
      }

      setAnchoreEl(e.currentTarget);
      setSelection(editor.selection);
    },
    [editor],
  );
  const handleClose = () => {
    if (onPopover) {
      onPopover(false);
    }
    setAnchoreEl(null);
    setSelection(null);
  };
  const handleBlankClick = useCallback(() => {
    Editor.removeMark(editor, 'color');
    handleClose();
  }, []);
  const handleColorChange = useCallback(
    (color) => {
      const rgbaString = rgbaObjToString(color);
      setColorValue(rgbaString);
      editor.addMark('color', rgbaString);
    },
    [selection],
  );
  const handleColorPick = useCallback(
    (colorObj) => {
      editor.selection = selection;
      setColorValue(colorObj.color);
      editor.addMark('color', colorObj.name);
      handleClose();
    },
    [selection],
  );
  const handleMouseDown = (e) => {
    if (e.target.nodeName !== 'INPUT') {
      e.preventDefault();
    };
  };

  useEffect(() => {
    if (anchoreEl && editor.selection) {
      setSelection(editor.selection);
    }
  }, [editor.selection]);

  const open = Boolean(anchoreEl);
  const id = open ? 'simple-popover' : undefined;
  let color = currentColor(editor);
  color = typeof color === 'string' ? color : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        handleClick={handleOpen}
        isActive={color}
        popover="Text Color"
        buttonProps={{
          'data-testid': 'TitleSection/ColorPickerButton',
        }}
      >
        <PreviewIcon isActive={!!color} color={colorWithDefault(color)} />
      </Button>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        onMouseDown={handleMouseDown}
        anchorEl={anchoreEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ sx: muiStyles.popover }}
      >
        <ReactColorPicker
          color={colorValue}
          handleColorPick={handleColorPick}
          onChange={handleColorChange}
          onChangeComplete={handleColorChange}
          resetColor={handleBlankClick}
          paletteColors={paletteColors}
          withAlpha
        />
      </Popover>
    </>
  );
}

export default ColorPicker;
