import { useState, useCallback } from 'react';

function useModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState();

  const showModal = (value) => {
    setValue(value);
    setIsOpen(true);
  };

  const hideModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    showModal,
    hideModal,
    value,
  };
}

export default useModal;
