import React from 'react';

function useHorizontalScroll() {
  const scrollContainerRef = React.useRef(null);
  const [isAtStart, setIsAtStart] = React.useState(true);
  const [isAtEnd, setIsAtEnd] = React.useState(false);

  React.useEffect(() => {
    if (!scrollContainerRef.current) {
      return;
    };
    setIsAtEnd(
      scrollContainerRef.current.scrollWidth ===
        scrollContainerRef.current.offsetWidth,
    );
  }, [scrollContainerRef]);

  const handleScroll = () => {
    if (!scrollContainerRef.current) {
      return;
    }

    setIsAtStart(scrollContainerRef.current.scrollLeft === 0);
    setIsAtEnd(
      Math.floor(
        scrollContainerRef.current.scrollWidth -
          scrollContainerRef.current.scrollLeft,
      ) <= scrollContainerRef.current.offsetWidth,
    );
  };

  return {
    scrollContainerRef,
    handleScroll,
    isAtStart,
    isAtEnd,
  };
};

export default useHorizontalScroll;
