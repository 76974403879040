import React from 'react';
import Box from '@mui/material/Box';
import { Swatch } from 'react-color/lib/components/common';
import ColorInput from '../ColorInput';
import AlphaInput from '../AlphaInput';

function InputBar(props) {
  const { color, withAlpha } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        border: '1px solid #D6D6D6',
        borderRadius: '8px',
        height: 36,
        margin: '24px 0',
        padding: '0 6px',
        overflow: 'hidden',
      }}
      data-testid="ReactColorPicker/InputBarContainer"
    >
      <Swatch
        style={{
          width: 24,
          height: 24,
          borderRadius: '4px',
        }}
        color={color.hex || color}
      />
      <ColorInput {...props} />
      {withAlpha && <AlphaInput {...props} />}
    </Box>
  );
}

export default InputBar;
