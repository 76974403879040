import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import ExpandIcon from '@mui/icons-material/Expand';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { MenuList } from '@mui/material';
import { useMuiPopover } from '../../../../../hooks';

const VALUES = [
  { value: '-4px', name: '-4' },
  { value: '-3px', name: '-3' },
  { value: '-2px', name: '-2' },
  { value: '-1px', name: '-1' },
  { value: 'normal', name: 'Default' },
  { value: '1px', name: '1' },
  { value: '2px', name: '2' },
  { value: '3px', name: '3' },
  { value: '4px', name: '4' },
  { value: '5px', name: '5' },
  { value: '6px', name: '6' },
  { value: '7px', name: '7' },
  { value: '8px', name: '8' },
];

const anchorId = 'letter-spacing-anchor';

function LetterSpacing({ onItemClick, selectedValue }) {
  const { id, open, anchorEl, handleOpen, handleClose } = useMuiPopover(
    'ButtonsContainer/LetterSpacing',
    anchorId,
  );

  const handleClick = (value) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    onItemClick(event, value);
    handleClose(event);
  };

  return (
    <>
      <Box
        data-testid="TitleSection/LetterSpacing"
        onClick={handleOpen}
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ExpandIcon
          sx={{
            color: 'rgba(51, 51, 51, 0.54)',
            transform: 'rotate(90deg)',
            marginRight: '15px',
          }}
        />
        <Typography>Letter spacing</Typography>
        <ArrowRightIcon
          id={anchorId}
          sx={{
            color: 'rgba(51, 51, 51, 0.54)',
            marginLeft: '30px',
          }}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginLeft: '15px' }}
        id={id}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        autoFocus={false}
      >
        <MenuList>
          {VALUES.map(({ name, value }) => (
            <MenuItem key={value} onClick={handleClick(value)}>
              <ListItemText sx={{ marginRight: '10px' }}>{name}</ListItemText>
              {value === selectedValue && (
                <ListItemIcon
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Check />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}

export default LetterSpacing;
