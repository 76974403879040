import React, { useState, useCallback } from 'react';
import Popper from '@mui/material/Popper';

import { Button, PopoverText } from './style';

function ChangeButton({
  isActive,
  handleClick,
  popover,
  children,
  buttonProps,
}) {
  const [anchorEl, setAnchoreEl] = useState(null);
  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    handleClick(e);
  }, []);
  const handleButtonClick = useCallback((e) => e.preventDefault(), []);
  const handlePopoverOpen = useCallback((event) => {
    setAnchoreEl(event.currentTarget);
  }, []);
  const handlePopoverClose = useCallback(() => {
    setAnchoreEl(null);
  }, []);
  const open = Boolean(anchorEl);
  const id = open ? 'mouse-over-popover' : undefined;

  return (
    <>
      <Button
        {...buttonProps}
        aria-describedby={id}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onMouseDown={handleMouseDown}
        onClick={handleButtonClick}
        isActive={isActive}
      >
        {children}
      </Button>
      <Popper
        sx={{ zIndex: 10 }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top"
        modifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: [0, 11],
            },
          },
        ]}
      >
        <PopoverText>{popover}</PopoverText>
      </Popper>
    </>
  );
}

export default ChangeButton;
