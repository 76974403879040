import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Editor, Range } from 'slate';
import { useSlate } from 'slate-react';

import Button from '../ChangeButton';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';

const POPOVERS = {
  italic: 'Italic',
  bold: 'Bold',
  strikethrough: 'Strikethrough',
  underline: 'Underline',
};

function withDeleteEmptyMark(editor) {
  const { deleteBackward } = editor;

  editor.deleteBackward = function(unit) {
    const { selection } = editor;

    if (
      selection &&
      selection.focus.offset === 0 &&
      selection.anchor.offset === 0 &&
      Range.isCollapsed(selection)
    ) {
      const marks = Editor.marks(editor);

      for (const mark of Object.keys(marks)) {
        Editor.removeMark(editor, mark);
      }
    }

    deleteBackward(unit);
  };

  return editor;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks && marks[format] === true;
};

const toggleMark = (editor, format) => {
  if (isMarkActive(editor, format)) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const pickIcon = (format) => {
  switch (format) {
    case 'italic':
      return FormatItalicIcon;
    case 'bold':
      return FormatBoldIcon;
    case 'strikethrough':
      return StrikethroughSIcon;
    case 'underline':
      return FormatUnderlinedIcon;
  }
};

function MarkButton({ format }) {
  const editor = useSlate();
  const Icon = useMemo(() => pickIcon(format), [format]);
  const isActive = isMarkActive(editor, format);
  const iconColor = isActive ? '#FFF' : '#333';
  const handleClick = () => {
    toggleMark(editor, format);
  };

  return (
    <Button
      isActive={isActive}
      handleClick={handleClick}
      popover={POPOVERS[format]}
      buttonProps={{
        'data-testid': `TitleSection/MarkButton/${format}`,
      }}
    >
      <Icon sx={{ fontSize: 20, color: iconColor }} data-testid={`MarkButton/${format}Icon`} />
    </Button>
  );
}

MarkButton.propTypes = {
  format: PropTypes.string.isRequired,
};

export { toggleMark, withDeleteEmptyMark };
export default MarkButton;
