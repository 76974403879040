import React from 'react';
import Loader from '../../loader';
import DragIcon from '../../icons/DragIcon';

import {
  InputWrapper,
  StyledInput,
  StyledChatbotPhoneInput,
  StyledErrorMessage,
  InputPrefixInline,
  StyledDraggableInput,
  ColorBox,
} from './style';

export const Input = React.forwardRef((props, ref) => {
  return (
    <InputWrapper noMargin={props.noMargin}>
      {props.isLoading && <Loader {...props} />}
      <StyledInput {...props} ref={ref} style={props.inputStyle} />
      {props.error ? (
        <StyledErrorMessage>{props.error}</StyledErrorMessage>
      ) : null}
    </InputWrapper>
  );
});

export const ChatbotPhoneInput = (props) => {
  return (
    <InputWrapper noMargin={props.noMargin}>
      {props.isLoading && <Loader {...props} />}
      <StyledChatbotPhoneInput {...props} />
      {props.actionButton}
      {props.error ? (
        <StyledErrorMessage>{props.error}</StyledErrorMessage>
      ) : null}
    </InputWrapper>
  );
};

export const DraggableInput = (props) => {
  return (
    <InputWrapper noMargin={props.noMargin}>
      <InputPrefixInline>
        <DragIcon fill="var(--font-color-secondary)" />
      </InputPrefixInline>
      <StyledDraggableInput {...props} />
      {props.error ? (
        <StyledErrorMessage>{props.error}</StyledErrorMessage>
      ) : null}
    </InputWrapper>
  );
};

export const ColorInput = ({ onColorBoxClick, color, noMargin, error, ...rest }) => {
  return (
    <InputWrapper noMargin={noMargin}>
      <ColorBox color={color} onClick={onColorBoxClick} />
      <StyledInput maxLength={7} style={{ paddingLeft: '3rem', textTransform: 'uppercase' }} {...rest} />
      {error ? (
        <StyledErrorMessage>{error}</StyledErrorMessage>
      ) : null}
    </InputWrapper>
  );
};
