import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 20px 8px 8px;
  height: 54px;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: var(--block-title-controls-background);
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--border-color-primary);
  flex: 1;

  &::after {
    content: ${props => props.rightGradient ? '""' : 'none'};
    position: absolute;
    right: 0;
    bottom: 0;
    height: 54px;
    width: 12px;
    border-radius: 0 0 8px 0;
    background: linear-gradient(
      90deg,
      rgba(229, 229, 229, 0.12) 0%,
      #bfbfbf 100%
    );
  }
  &::before {
    content: ${props => props.leftGradient ? '""' : 'none'};
    position: absolute;
    left: 0;
    bottom: 0;
    height: 54px;
    width: 12px;
    border-radius: 0 0 0 8px;
    background: linear-gradient(
      270deg,
      rgba(229, 229, 229, 0.12) 0%,
      #bfbfbf 100%
    );
  }
`;

export const Divider = styled.div`
  width: 2px;
  height: 36px;
  flex-shrink: 0;
  flex-shrink: 0;
  background-color: var(--border-color-primary);
  margin: 0 4px;
`;
