import { useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'increment':
      return {count: state.count + action.payload};
    case 'decrement':
      return {count: state.count - action.payload};
    default:
      throw new Error();
  };
};

function useCounter(defaultValue) {
  const [state, dispatch] = useReducer(reducer, { count: defaultValue });

  function increment(step) {
    dispatch({type: 'increment', payload: step});
  }

  function decrement(step) {
    dispatch({type: 'decrement', payload: step});
  }

  return [state.count, increment, decrement];
};

export default useCounter;
