import React, { useState, useCallback } from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import Popover from '@mui/material/Popover';
import ReactColorPicker from '../../../ReactColorPicker';

import Button from '../ChangeButton';
import { muiStyles } from './style';
import { colorWithDefault, getInitialColor } from '../../utils';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import { rgbaObjToString } from '../../../../../../utils';

const currentHighlight = (editor) => {
  const marks = Editor.marks(editor);
  return marks && marks.highlight;
};

const initialColor = (editor, paletteColors) => {
  const marks = Editor.marks(editor);
  return getInitialColor(marks, 'highlight', paletteColors);
};

const DEFAULT_COLOR = 'rgba(255, 255, 255, 1)';

function HighlightPicker({ onPopover, paletteColors }) {
  const editor = useSlate();
  const [anchoreEl, setAnchoreEl] = useState(null);
  const [colorValue, setColorValue] = useState('');
  const [selection, setSelection] = useState(null);

  const handleOpen = useCallback(
    (e) => {
      if (onPopover) {
        onPopover(true);
      }
      const highlight = initialColor(editor, paletteColors);

      if (highlight) {
        setColorValue(highlight);
      } else {
        setColorValue(DEFAULT_COLOR);
      }

      setAnchoreEl(e.currentTarget);
      setSelection(editor.selection);
    },
    [editor],
  );
  const handleClose = useCallback(() => {
    if (onPopover) {
      onPopover(false);
    }
    setAnchoreEl(null);
    setSelection(null);
  }, []);
  const handleBlankClick = useCallback(() => {
    Editor.removeMark(editor, 'highlight');
    handleClose();
  }, []);
  const handleColorChange = useCallback(
    (color) => {
      const rgbaString = rgbaObjToString(color);
      setColorValue(rgbaString);
      editor.addMark('highlight', rgbaString);
    },
    [selection],
  );
  const handleColorPick = useCallback(
    (colorObj) => {
      editor.selection = selection;
      setColorValue(colorObj.color);
      editor.addMark('highlight', colorObj.name);
      handleClose();
    },
    [selection],
  );
  const handleMouseDown = (e) => {
    if (e.target.nodeName !== 'INPUT') {
      e.preventDefault();
    };
  };

  const open = Boolean(anchoreEl);
  const id = open ? 'simple-popover' : undefined;
  let highlight = currentHighlight(editor);
  highlight = typeof highlight === 'string' ? highlight : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        handleClick={handleOpen}
        isActive={!!highlight}
        popover="Highlight Color"
        buttonProps={{
          'data-testid': 'TitleSection/ColorHighlightButton',
        }}
      >
        <FontDownloadIcon sx={{ color: colorWithDefault(highlight), fontSize: 20 }} />
      </Button>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        onMouseDown={handleMouseDown}
        anchorEl={anchoreEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ sx: muiStyles.popover }}
      >
        <ReactColorPicker
          color={colorValue}
          handleColorPick={handleColorPick}
          onChange={handleColorChange}
          onChangeComplete={handleColorChange}
          resetColor={handleBlankClick}
          paletteColors={paletteColors}
          withAlpha
        />
      </Popover>
    </>
  );
}

export default HighlightPicker;
