import React from 'react';
import { EditableInput } from 'react-color/lib/components/common';

function AlphaInput(props) {
  const inputStyles = {
    wrap: {
      width: '62px',
      height: '100%',
    },
    input: {
      height: '100%',
      width: '62px',
      margin: 0,
      fontSize: '16px',
      padding: '0.5rem',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      textAlign: 'center',
      borderLeft: '1px solid #BDBDBD',
      borderRadius: 0,
    },
  };
  const handleChange = (value) => {
    let opacity = Number(value.replace('%', '').trim() / 100);
    if (isNaN(opacity) || opacity > 1) {
      opacity = 1;
    }
    if (opacity <= 0) {
      opacity = 0;
    }
    const colorObj = {
      rgb: { ...props.rgb, a: opacity },
      hex: props.hex,
    };
    props.onChangeComplete(colorObj);
  };

  return (
    <EditableInput
      onChange={handleChange}
      value={`${Math.round(props.rgb.a * 100)}%`}
      style={inputStyles}
    />
  );
}

export default AlphaInput;
