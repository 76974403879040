import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 168px;
  background: var(--block-title-controls-background);
  border: 0px solid rgba(0, 0, 0, 0.25);
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 4px;
  border-radius: 8px;
`;

export const Label = styled.div`
  margin-top: 20px;
  margin-left: 16px;
  font-size: 12px;
  color: var(--font-color-secondary--middle);
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  z-index: 1;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PrimaryActionButton = styled.button`
  border-radius: 0 0 8px 0;
`;

export const ActionButton = styled.button`
  border-radius: 0 0 0 8px;
  background-color: var(--button-background);
  color: var(--font-color-secondary--middle);
  &:hover {
    color: var(--font-color-secondary--middle);
    background-color: var(--button-background--hover);
  }
`;

export const InputError = styled.span`
  border-radius: 5px;
  text-align: center;
  background-color: var(--error);
  color: var(--white);
  font-size: 12px;
  padding: 3px 9px;
  position: absolute;
  right: 24px;
  top: 35px;
`;

export const InputWrapper = styled.div`
  width: 300px;
  margin: 8px 16px 16px 16px;
`;
