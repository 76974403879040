import { Transforms, Editor, Node } from 'slate';
import { ReactEditor } from 'slate-react';
import { nanoid } from 'nanoid';

export const isWhite = (value) => /(255, ){3}/.test(value);

export const colorWithDefault = (color) => {
  if (!color) {
    return 'rgba(51, 51, 51, 1)';
  }
  return isWhite(color) ? color.replace('255, 255, 255', '51, 51, 51') : color;
};

export function buildSlateElements(value = '') {
  if (Array.isArray(value)) {
    return value;
  }

  if (!value) {
    return [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ];
  }

  const children = value.split(/({{.*?}})/g).map((text) => {
    const textIsReference = /{{(?!#|\/).*}}/.test(text);

    if (textIsReference) {
      return {
        mentionId: nanoid(),
        type: 'mention',
        children: [{ text: '' }],
        reference: text,
      };
    } else {
      return { text };
    }
  });

  return [
    {
      type: 'paragraph',
      children,
    },
  ];
}

function elementToText(node) {
  if (!node.type) {
    return node.text;
  }

  const childrens = elementsToText(node.children);

  switch (node.type) {
    case 'mention':
      return node.reference;
    default:
      return childrens;
  }
}

export function elementsToText(nodes) {
  return nodes.map(elementToText).join('');
}

export function withSingleLine(editor) {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
}

export function getListItemStyle(node, attribute, defaultValue) {
  return (node.children[0] && node.children[0][attribute])
        /* When we have link first in line it's always surrounded by empty text elements */
        || (node.children[1]?.type === 'link' && node.children[1].children[0][attribute])
        /* */
        || defaultValue;
}

export function getAnchorElement(editor) {
  const [node] = Editor.node(editor, editor.selection);
  const selectedEl = ReactEditor.toDOMNode(editor, node);
  const getBoundingClientRect = () => {
    return selectedEl.getBoundingClientRect();
  };

  return {
    getBoundingClientRect,
    nodeType: 1,
  };
}

function findAllIndexes(inputString, searchChar) {
  const indexes = [];
  for (let index = 0; index < inputString.length; index++) {
    if (inputString[index] === searchChar) {
      indexes.push(index);
    }
  }
  return indexes;
}

export function prevCharIs(editor, char) {
  const focus = editor.selection.focus;
  const fragment = Editor.fragment(editor, focus.path);
  const indexesOfChar = findAllIndexes(Node.string(fragment[0]), char);

  if (indexesOfChar.length > 0) {
    return indexesOfChar.includes(focus.offset - 1);
  }
}
