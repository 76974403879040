export const muiStyles = {
  popover: {
    '&.MuiPaper-root': {
      marginTop: '20px',
      marginLeft: '-12px',
      borderRadius: '8px',
      overflow: 'initial',
    },
  },
  input: {
    backgroundColor: '#DADADA',
    borderRadius: '0 8px 8px 0',
    width: '110px',
    '&::before': {
      content: 'none',
    },
    '&::after': {
      content: 'none',
    },
    '& > input': {
      margin: '0',
      backgroundColor: '#DADADA',
      padding: '8px 16px',
    },
    '& > input:focus': {
      borderColor: 'unset',
      backgroundColor: '#DADADA',
      boxShadow: 'unset',
    },
    '&.Mui-focused': {
      border: 'none',
    },
  },
};
