import React, { useCallback } from 'react';
import { Node, Transforms } from 'slate';
import PropTypes from 'prop-types';
import { ReactEditor } from 'slate-react';
import { nanoid } from 'nanoid';
import MentionChip from '../../../MentionChip';

function withMention(editor) {
  const { isInline, isVoid, addMark, removeMark } = editor;
  let markStarted = false;

  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    if (markStarted && element.type === 'mention') {
      return false;
    }

    return element.type === 'mention' ? true : isVoid(element);
  };

  editor.addMark = (key, value) => {
    markStarted = true;
    addMark(key, value);
    markStarted = false;
  };

  editor.removeMark = (key) => {
    markStarted = true;
    removeMark(key);
    markStarted = false;
  };

  return editor;
}

const getMention = (reference, fragment, richText) => {
  const children = fragment ? fragment[0].children : [{ text: '' }];

  return {
    mentionId: nanoid(),
    type: 'mention',
    children,
    reference,
    richText,
  };
};

const insertMention = (editor, reference, noTrailingSpace, available_types) => {
  const isRichText = available_types && available_types.includes('rich_text');
  const fragment = editor.getFragment();
  const selectionFocus = editor.selection.focus;
  const fragmentStyles = fragment[0].children[0];
  const mention = getMention(reference.replace(' ', ''), fragment, isRichText);

  editor.deleteBackward(1);

  if (isRichText && fragmentStyles.type === 'list-item') {
    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);

    return;
  }

  if (isRichText && selectionFocus.offset === 1) {
    // when the upper row is empty after inserted rich text ref node we're removing it
    const rowToDelete = selectionFocus.path[0];
    Transforms.removeNodes(editor, { at: [rowToDelete] });
  }

  Transforms.insertNodes(
    editor,
    isRichText
      ? { type: 'rich-text-block', children: [mention, { ...fragmentStyles, text: ' ' }] }
      : mention,
  );

  Transforms.move(editor);
  ReactEditor.focus(editor);

  if (!noTrailingSpace && !isRichText) {
    Transforms.insertNodes(editor, { ...fragmentStyles, text: ' ' });
  }
};

function Mention(props) {
  const { editor, element } = props;
  const handleDelete = useCallback(() => {
    let path;

    for (const [node, currentPath] of Node.nodes(editor)) {
      if (node.type === 'mention' && node.mentionId === element.mentionId) {
        path = currentPath;
        break;
      }
    }

    Transforms.removeNodes(editor, { at: path });
  }, [editor, element]);

  return <MentionChip onDelete={handleDelete} {...props} />;
}

Mention.propTypes = {
  element: PropTypes.shape({
    reference: PropTypes.string,
  }).isRequired,
  editor: PropTypes.shape({
    deleteBackward: PropTypes.func,
  }).isRequired,
};

export { withMention, insertMention, getMention };
export default Mention;
