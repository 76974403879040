import React from 'react';

function Pointer() {
  return (
    <div
      style={{
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        border: '4px solid #fff',
        background: 'transparent',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
        transform: 'TranslateY(-4px)',
      }}
    />
  );
}

export default Pointer;
