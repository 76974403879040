export const styles = {
  paper: {
    '& .MuiCalendarPicker-root': {
      '& button': {
        width: 'auto',
      },
      '& button:hover': {
        color: 'var(--primary-color)',
      },
      '& .PrivatePickersYear-root button': {
        width: 72,
        p: 0,
      },
      '& .PrivatePickersYear-root button.Mui-selected': {
        backgroundColor: 'var(--primary-color)',
        '&:hover': {
          color: '#fff',
        },
      },
      '& .PrivatePickersSlideTransition-root': {
        '& .MuiButtonBase-root': {
          width: 36,
        },
      },
      '& .MuiCalendarPicker-viewTransitionContainer': {
        '& .MuiPickersDay-today': {
          borderColor: 'var(--primary-color)',
        },
        '& .MuiButtonBase-root.Mui-selected': {
          backgroundColor: 'var(--primary-color)',
          '&:hover': {
            color: '#fff',
          },
        },
      },
    },
  },
};

