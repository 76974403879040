import React from 'react';
import Box from '@mui/material/Box';
import { Alpha } from 'react-color/lib/components/common';
import Pointer from '../Pointer';

function AlphaBar(props) {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '8px',
        margin: '24px 0',
        borderRadius: '2px',
      }}
    >
      <Alpha
        {...props}
        pointer={Pointer}
      />
    </Box>
  );
}

export default AlphaBar;
