const getCallbacks = (key) => {
  if (!window.sharedStoreCallbacks) {
    window.sharedStoreCallbacks = {};
  }

  if (!window.sharedStoreCallbacks[key]) {
    window.sharedStoreCallbacks[key] = [];
  }

  return window.sharedStoreCallbacks[key];
};

const getStore = () => {
  if (!window.sharedStoreValues) {
    window.sharedStoreValues = {};
  }

  return window.sharedStoreValues;
};

const getValueFromStore = (key) => {
  const store = getStore();

  return store[key];
};

const setValueToStore = (key, val) => {
  const store = getStore();
  const callbacks = getCallbacks(key);

  store[key] = val;

  if (callbacks) {
    for (const func of callbacks) {
      func(val);
    }
  }
};

const subscribeTo = (key, callback) => {
  getCallbacks(key).push(callback);
};

const unsubscribeFrom = (key) => {
  delete window.sharedStoreCallbacks[key];
};

export { setValueToStore, subscribeTo, unsubscribeFrom, getValueFromStore };
