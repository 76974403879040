import React, { useEffect, useReducer } from 'react';
import {
  createMergeField,
  getCreateMergeFieldApiCall,
} from '../components/api';
import _isEmpty from 'lodash/isEmpty';

export const ADD_REFERENCE = 'addReference';
export const SET_REFERENCE_VALUE = 'setReferenceValue';
export const SET_REFERENCE_IS_LOADING = 'setReferenceIsLoading';
export const SET_API_AUTH = 'setApiAuth';

function reducer(state, action) {
  switch (action.type) {
    case ADD_REFERENCE:
      if (!state[action.payload.reference]) {
        return {
          ...state,
          references: {
            ...state.references,
            [action.payload.reference]: {},
          },
        };
      } else {
        return state;
      }
    case SET_REFERENCE_IS_LOADING:
      return {
        ...state,
        references: {
          ...state.references,
          [action.payload.reference]: {
            isLoading: true,
          },
        },
      };
    case SET_REFERENCE_VALUE:
      return {
        ...state,
        references: {
          ...state.references,
          [action.payload.reference]: {
            ...action.payload,
            isLoading: false,
          },
        },
      };
    case SET_API_AUTH:
      return {
        ...state,
        host: action.payload.host,
        auth: action.payload.auth,
      };
    default:
      return state;
  }
}

const ReferencesContext = React.createContext({});
ReferencesContext.displayName = 'References Context';

export function ReferencesProvider({ children, haHost, haAuth }) {
  const referencesState = useReducer(reducer, {
    references: {},
  });
  const [{ references, host, auth }, dispatch] = referencesState;

  const referenceApiMethod =
    host && auth ? getCreateMergeFieldApiCall(host, auth) : createMergeField;

  useEffect(() => {
    if (haAuth && haHost) {
      dispatch({
        type: SET_API_AUTH,
        payload: {
          auth: haAuth,
          host: haHost,
        },
      });
    }
  }, []);

  useEffect(() => {
    const reference = Object.keys(references).find((key) =>
      _isEmpty(references[key]),
    );

    if (reference) {
      dispatch({
        type: SET_REFERENCE_IS_LOADING,
        payload: { reference },
      });
      referenceApiMethod({ reference }, host, auth).then((response) => {
        dispatch({
          type: SET_REFERENCE_VALUE,
          payload: {
            ...response,
            reference,
          },
        });
      });
    }
  }, [references]);

  return (
    <ReferencesContext.Provider value={referencesState}>
      {children}
    </ReferencesContext.Provider>
  );
}

export default ReferencesContext;
