import React from 'react';

import { colorWithDefault } from './utils';

function Leaf({ attributes, children, leaf }) {
  if (leaf.placeholder) {
    return <span {...attributes}>{children}</span>;
  }

  const styles = {};
  const classes = [];

  if (leaf.lineHeight) {
    styles.lineHeight = leaf.lineHeight;
    classes.push('withLineHeight');
  }

  if (leaf.textTransform) {
    styles.textTransform = leaf.textTransform;
  }

  if (leaf.letterSpacing) {
    styles.letterSpacing = leaf.letterSpacing;
  }

  if (leaf.color) {
    styles.color = colorWithDefault(leaf.color);
    classes.push('colored');
  }

  if (leaf.highlight) {
    classes.push('highlighted');

    if (leaf.text !== '') {
      styles.backgroundColor = colorWithDefault(leaf.highlight);
    }
  }

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.fontFamily) {
    styles.fontFamily = leaf.fontFamily;
  }

  if (leaf.fontWeight) {
    styles.fontWeight = leaf.fontWeight;
  }
  return (
    <span style={styles} {...attributes} className={classes.join(' ')}>
      {children}
    </span>
  );
}

export default Leaf;
