import React from 'react';
import Box from '@mui/material/Box';
import { Hue } from 'react-color/lib/components/common';
import Pointer from '../Pointer';

function HueBar(props) {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '8px',
        margin: '24px 0',
        borderRadius: '2px',
      }}
    >
      <Hue {...props} pointer={Pointer} onChange={props.onChange} />
    </Box>
  );
}

export default HueBar;
