import React, { useEffect, useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { X } from 'react-feather';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getValueFromStore,
  setValueToStore,
  subscribeTo,
  unsubscribeFrom,
} from '../../sharedStore';
import { createMergeField, getCreateMergeFieldApiCall } from '../api';
import ReferencesContext from '../../contexts/ReferencesContext';

const TooltipTitle = styled.div`
  a {
    color: #fff;
    text-decoration: none;
  }
`;

function MentionChip({ attributes, children, element, onDelete }) {
  const [info, setInfo] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { name, description } = info;
  const { reference } = element;
  const [referencesState, setReferences] = useContext(ReferencesContext);

  const handleDelete = useCallback(
    (e) => {
      setValueToStore(reference, null);
      onDelete(e);
    },
    [reference],
  );

  const Label = () => (
    <>
      {name || <CircularProgress sx={{ color: '#fff' }} size={14} />}
      {children}
    </>
  );

  useEffect(() => {
    subscribeTo(reference, (info) => {
      if (info) {
        setInfo(info);
      }
    });

    return () => unsubscribeFrom(reference);
  }, []);

  useEffect(() => {
    if (!isEmpty(info)) {
      return;
    }
    const infoFromStore = getValueFromStore(reference);

    if (infoFromStore) {
      setInfo(infoFromStore);
      return;
    }

    const { auth, host } = referencesState;
    const referenceApiMethod =
      host && auth ? getCreateMergeFieldApiCall(host, auth) : createMergeField;

    referenceApiMethod({ reference }, host, auth).then((response) => {
      if (isEmpty(response)) {
        return;
      }

      setReferences((prevReferences) => ({
        ...prevReferences,
        [reference]: response,
      }));
      setValueToStore(reference, response);
    });
  }, [info]);

  const handleTooltipOpen = () => setTooltipOpen(true);
  const handleTooltipClose = () => setTooltipOpen(false);

  return (
    <Tooltip
      title={
        (description && (
          <TooltipTitle dangerouslySetInnerHTML={{ __html: description }} />
        )) ||
        ''
      }
      style={{ userSelect: 'none' }}
      open={tooltipOpen}
      onOpen={handleTooltipOpen}
      onClose={handleTooltipClose}
      {...attributes}
      contentEditable={false}
    >
      <Chip
        component="span"
        label={<Label />}
        onDelete={onDelete ? handleDelete : null}
        onClick={handleTooltipOpen}
        deleteIcon={
          <X
            size={16}
            strokeWidth={2}
            color="#BDBDBD"
            data-testid="MentionChip/Delete"
          />
        }
        data-testid="MentionChip/Chip"
        sx={{
          '&.MuiButtonBase-root,&.MuiChip-root': {
            backgroundColor: '#727278',
            borderRadius: '4px',
            color: '#fff',
            margin: '4px 0',
          },
          '& .MuiChip-label': {
            minWidth: '50px',
          },
          '& .MuiChip-deleteIcon': {
            width: '16px',
            height: '16px',
            color: '#BDBDBD',
            strokeWidth: '2px',
            flexShrink: 0,
          },
        }}
      />
    </Tooltip>
  );
}

MentionChip.propType = {
  attributes: PropTypes.shape({}),
  element: PropTypes.shape({}).isRequired,
  onDelete: PropTypes.fund,
};

export default MentionChip;
