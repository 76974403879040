import { getListItemStyle } from '../../../SlateElements/components/utils';

export const isWhite = (value) => /(255, ){3}/.test(value);

export const colorWithDefault = (color) =>
  !color || isWhite(color) ? '#333' : color;

function serializeLeafToHtml(node) {
  const tagName = node.richText ? 'div' : 'span';
  let children = node.text;
  let openTag = `<${tagName}>`;
  const styles = [];
  const classes = [];
  const attrs = [];

  if (node.lineHeight) {
    styles.push(`line-height: ${node.lineHeight};`);
    classes.push('withLineHeight');
  }

  if (node.textTransform) {
    styles.push(`text-transform: ${node.textTransform};`);
  }

  if (node.letterSpacing) {
    styles.push(`letter-spacing: ${node.letterSpacing};`);
  }

  if (node.color) {
    styles.push(`color: ${node.color};`);
    classes.push('colored');
  }

  if (node.highlight) {
    classes.push('highlighted');

    if (children !== '') {
      styles.push(`background-color: ${node.highlight};`);
    }
  }

  if (node.fontFamily) {
    styles.push(`font-family: ${node.fontFamily};`);
  }

  if (node.fontWeight) {
    styles.push(`font-weight: ${node.fontWeight};`);
  }

  if (node.richText) {
    styles.push('display: inline-block;');
  }

  if (classes.length > 0) {
    attrs.push(`class="${classes.join(' ')}"`);
  }

  if (styles.length > 0) {
    attrs.push(`style="${styles.join(' ')}"`);
  }

  if (attrs.length > 0) {
    openTag = `<${tagName} ${attrs.join(' ')}>`;
  }

  if (node.bold) {
    children = `<strong>${children}</strong>`;
  }

  if (node.italic) {
    children = `<em>${children}</em>`;
  }

  if (node.strikethrough) {
    children = `<s>${children}</s>`;
  }

  if (node.underline) {
    children = `<u>${children}</u>`;
  }

  return [openTag, children, `</${tagName}>`].join('');
}

function serializeNodeToHtml(node) {
  if (!node.type) {
    return serializeLeafToHtml(node);
  }

  if (node.type === 'mention') {
    return serializeLeafToHtml({
      ...node.children[0],
      text: node.reference,
      richText: node.richText,
    });
  }

  const childrens = node.children.map(serializeNodeToHtml).join('');
  const nodeAlignStyle = node.align ? `text-align: ${node.align};` : '';
  const nodeChildrenAlign = node.children[0]?.align;

  switch (node.type) {
    case 'link':
      return `<a href="${node.url}" target="_blank">${childrens}</a>`;
    case 'bulleted-list':
      return nodeChildrenAlign
        ? `<div style="text-align: ${nodeChildrenAlign};"><ul style="display: inline-block;">${childrens}</ul></div>`
        : `<ul>${childrens}</ul>`;
    case 'list-item':
      const color = getListItemStyle(node, 'color', 'inherit');
      const fontFamily = getListItemStyle(node, 'fontFamily', 'inherit');
      const fontWeight = getListItemStyle(node, 'fontWeight', '400');
      const letterSpacing = getListItemStyle(node, 'letterSpacing', 'normal');
      const lineHeight = getListItemStyle(node, 'lineHeight', '1.5');
      return `<li style="color: ${color}; font-family: ${fontFamily}; font-weight: ${fontWeight}; letter-spacing: ${letterSpacing}; line-height: ${lineHeight}; text-align: left;">${childrens}</li>`;
    case 'numbered-list':
      return nodeChildrenAlign
        ? `<div style="text-align: ${nodeChildrenAlign};"><ol style="display: inline-block;">${childrens}</ol></div>`
        : `<ol>${childrens}</ol>`;
    case 'heading-1':
      return `<h1 style="${nodeAlignStyle}">${childrens}</h1>`;
    case 'heading-2':
      return `<h2 style="${nodeAlignStyle}">${childrens}</h2>`;
    case 'heading-3':
      return `<h3 style="${nodeAlignStyle}">${childrens}</h3>`;
    case 'heading-4':
      return `<h4 style="${nodeAlignStyle}">${childrens}</h4>`;
    case 'paragraph':
      return `<p style="${nodeAlignStyle}">${childrens}</p>`;
    case 'rich-text-block':
      return `<div style="${nodeAlignStyle}">${childrens}</div>`;
    case 'caption':
      return `<small style="${nodeAlignStyle}">${childrens}</small>`;
    default:
      return `<p style="${nodeAlignStyle}">${childrens}</p>`;
  }
}

export function slateToHtml(nodes) {
  return nodes.map(serializeNodeToHtml).join('');
}

function serializeNodeToText(node) {
  if (!node.type) {
    return node.text;
  }

  const childrens = node.children.map(serializeNodeToText).join('');

  switch (node.type) {
    case 'link':
      return `${childrens}(${node.url})\n`;
    case 'bulleted-list':
    case 'numbered-list':
      return childrens;
    case 'mention':
      return node.reference;
    default:
      return `${childrens}\n`;
  }
}

export function slateToText(nodes) {
  return nodes.map(serializeNodeToText).join('');
}

function serializeNodeToElement(node) {
  if (!node.type) {
    return node;
  }

  const children = node.children.map(serializeNodeToElement);

  switch (node.type) {
    case 'mention':
      const { type, reference, mentionId, richText } = node;
      return { children, type, reference, mentionId, richText };
    default:
      return { ...node, children };
  }
}

export function slateToElements(nodes) {
  return nodes.map(serializeNodeToElement);
}

export function getInitialColor(marks, attr, paletteColors) {
  if (marks && marks[attr]) {
    const color = marks[attr];

    if (color.includes('var(--')) {
      return paletteColors.find(c => c.name === color)?.color;
    } else {
      return color;
    }
  }
}
