import React from 'react';
import { useSlate } from 'slate-react';
import { Transforms, Editor, Element as SlateElement } from 'slate';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ChangeButton from '../ChangeButton';
import { useMuiPopover } from '../../../../../hooks';

const VALUES = [
  { value: 'heading-1', name: 'Heading 1', sx: { fontSize: '18px' } },
  { value: 'heading-2', name: 'Heading 2', sx: { fontSize: '16px' } },
  { value: 'heading-3', name: 'Heading 3', sx: { fontSize: '14px' } },
  { value: 'heading-4', name: 'Heading 4', sx: { fontSize: '12px' } },
  { value: 'paragraph', name: 'Paragraph', sx: { fontSize: '14px' } },
  { value: 'caption', name: 'Caption', sx: { fontSize: '12px' } },
];

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

function ParagraphButton({ onPopover }) {
  const {
    id,
    open,
    anchorEl,
    handleOpen: handleCurrentOpen,
    handleClose: handleCurrentClose,
  } = useMuiPopover();
  const editor = useSlate();

  const handleItemClick = (value) => () => {
    Transforms.setNodes(editor, { type: value });
    handleClose();
  };

  const handleOpen = (event) => {
    handleCurrentOpen(event);
    if (onPopover) {
      onPopover(true);
    }
  };

  const handleClose = (event) => {
    handleCurrentClose(event);
    if (onPopover) {
      onPopover(false);
    }
  };

  return (
    <>
      <ChangeButton
        isActive={open}
        handleClick={handleOpen}
        popover="Header Style"
        buttonProps={{
          'data-testid': 'TitleSection/BlockButton/FontSize',
        }}
      >
        <TextFieldsIcon
          sx={{ fontSize: 20, color: `${open ? '#FFF' : '#333'}` }}
          data-testid="MarkButton/titleIcon"
        />
      </ChangeButton>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {VALUES.map(({ name, value, sx }) => (
          <MenuItem
            key={value}
            onClick={handleItemClick(value)}
            selected={isBlockActive(editor, value)}
            data-testid={`TitleSection/BlockButton/FontSize/${value}`}
          >
            <Typography variant={value} sx={sx}>
              {name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default ParagraphButton;
